/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;

/**
 * Custom base styles, applied after the tailwind-base classes
 */

html {
  font-size: 10px;
  font-family: 'Poppins, sans-serif';
  background-color: white;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 1.4rem;
  line-height: 2rem;
  overflow-x: hidden;
}

html,
body,
#root {
  position: relative;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

h1,
.h1 {
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 300;
}

h2,
.h2 {
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 300;
}

h3,
.h3 {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 300;
}

h4,
.h4 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role='button'] {
  text-decoration: none;
}

[role='tooltip'] {
  z-index: 9999;
}

form label {
  z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
  transition: none !important;
  animation: none !important;
}

button:focus {
  outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transitiondelay: 9999s;
  transitionproperty: background-color, color;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:focus {
  outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.MuiPickersMonth-monthButton.Mui-disabled,
.MuiPickersYear-yearButton.Mui-disabled {
  color: lightgray;
}
